import React from 'react';
import { Container } from 'reactstrap';
import * as styles from './bodyCopy.module.scss';

interface Props {
  children: JSX.Element | JSX.Element[];
  id?: string;
}

const BodyCopy = (props: Props): JSX.Element => {
  const { children, id } = props;
  return (
    <Container id={id}>
      <div className={styles['body-copy']}>{children}</div>
    </Container>
  );
};

export default BodyCopy;
