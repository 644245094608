import React from 'react';
import { Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Head from '../components/head/head';
import NavBar, { NavBarSpacer } from '../components/nav-bar/navBar';
import Footer from '../components/footer/footer';
import BodyCopy from '../components/body-copy/bodyCopy';

const Home = (): JSX.Element => {
  return (
    <Container fluid>
      <Head />
      <NavBar />
      <NavBarSpacer />
      <BodyCopy>
        <article>
          <h1>HOP Technologies Pty Ltd Terms and Conditions</h1>
          <div>Last updated: August 1, 2020</div>
          <h2>1. Contractual Relationship</h2>
          <div>
            These Terms of Use (“Terms”) govern the access or use by you, an
            individual, from within any country in the world of applications,
            websites, content, products, and services (the “Services”) made
            available by HOP Technologies Pty Ltd, a private limited liability
            company established in Australia, having its offices located at
            Suite 205, 2-8 Brookhollow Avenue, Baulkham Hills NSW 2153,
            registered with Australian Securities and Investment Commission as
            ‘HOP’.
          </div>
          <div>
            PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE
            SERVICES.
          </div>
          <div>
            Your access and use of the Services constitutes your agreement to be
            bound by these Terms, which establishes a contractual relationship
            between you and HOP. If you do not agree to these Terms, you may not
            access or use the Services. These Terms expressly supersede prior
            agreements or arrangements with you. HOP may immediately terminate
            these Terms or any Services with respect to you, or generally cease
            offering or deny access to the Services or any portion thereof, at
            any time for any reason.
          </div>
          <div>
            Supplemental terms may apply to certain Services, such as policies
            for a particular event, activity or promotion, and such supplemental
            terms will be disclosed to you in connection with the applicable
            Services. Supplemental terms are in addition to, and shall be deemed
            a part of, the Terms for the purposes of the applicable Services.
            Supplemental terms shall prevail over these Terms in the event of a
            conflict with respect to the applicable Services. HOP may amend the
            Terms related to the Services from time to time.
          </div>
          <div>
            Amendments will be effective upon HOP’s posting of such updated
            Terms at this location or the amended policies or supplemental terms
            on the applicable Service. Your continued access or use of the
            Services after such posting constitutes your consent to be bound by
            the Terms, as amended.
          </div>
          <div>
            Our collection and use of personal information in connection with
            the Services is as provided in HOP’s Privacy Policy located at
            myhop.io/privacy. HOP may provide to a claims processor or an
            insurer any necessary information (including your contact
            information) if there is a complaint, dispute or conflict, which may
            include an accident, involving you and a Third Party Provider
            (including a transportation network company driver) and such
            information or data is necessary to resolve the complaint, dispute
            or conflict.
          </div>
          <h2>2. The Services</h2>
          <div>
            The Services constitute a technology platform that enables users of
            HOP’s mobile applications or websites provided as part of the
            Services (each, an “Application”) to arrange and schedule
            transportation and/or logistics services with independent third
            party providers of such services, including independent third party
            transportation providers and independent third party logistics
            providers under agreement with HOP or certain of HOP’s affiliates
            (“Third Party Providers”). Unless otherwise agreed by HOP ina
            separate written agreement with you, the Services are made available
            solely for your personal, non-commercial use. YOU ACKNOWLEDGE THAT
            HOP DOES NOT PROVIDE TRANSPORTATION OR LOGISTICS SERVICES OR
            FUNCTION AS A TRANSPORTATION CARRIER AND THAT ALL SUCH
            TRANSPORTATION OR LOGISTICS SERVICES ARE PROVIDED BY INDEPENDENT
            THIRD PARTY CONTRACTORS WHO ARE NOT EMPLOYED BY HOP OR ANY OF ITS
            AFFILIATES.
          </div>
          <h3>License</h3>
          <div>
            Subject to your compliance with these Terms, HOP grants you a
            limited, non- exclusive, non-sublicensable, revocable,
            non-transferrable license to: (i) access and use the Applications on
            your personal device solely in connection with your use of the
            Services; and (ii) access and use any content, information and
            related materials that may be made available through the Services,
            in each case solely for your personal, non- commercial use. Any
            rights not expressly granted herein are reserved by HOP and HOP’s
            licensors.
          </div>
          <h3>Restrictions</h3>
          <div>
            You may not: (i) remove any copyright, trademark or other
            proprietary notices from any portion of the Services; (ii)
            reproduce, modify, prepare derivative works based upon, distribute,
            license, lease, sell, resell, transfer, publicly display, publicly
            perform, transmit, stream, broadcast or otherwise exploit the
            Services except as expressly permitted by HOP; (iii) decompile,
            reverse engineer or disassemble the Services except as may be
            permitted by applicable law; (iv) link to, mirror or frame any
            portion of the Services; (v) cause or launch any programs or scripts
            for the purpose of scraping, indexing, surveying, or otherwise data
            mining any portion of the Services or unduly burdening or hindering
            the operation and/or functionality of any aspect of the Services; or
            (vi) attempt to gain unauthorized access to or impair any aspect of
            the Services or its related systems or networks.
          </div>
          <h3>Provision of the Services</h3>
          <div>
            You acknowledge that portions of the Services may be made available
            under HOP’s various brands or request options associated with
            transportation or logistics, including the transportation request
            brands currently referred to as “HOP,” “HopPlus,” “HopPro,”
            “HopLux,” and the logistics request brands currently referred to as
            “HopDemand’”. You also acknowledge that the Services may be made
            available under such brands or request options by or in connection
            with: (i) certain of HOP’s subsidiaries and affiliates; or (ii)
            independent Third Party Providers, including transportation network
            company drivers, transportation charter permit holders or holders of
            similar transportation permits, authorizations or licenses.
          </div>
          <div>Third Party Services and Content</div>
          <div>
            The Services may be made available or accessed in connection with
            third party services and content (including advertising) that HOP
            does not control. You acknowledge that different terms of use and
            privacy policies may apply to your use of such third-party services
            and content. HOP does not endorse such third-party services and
            content and in no event shall HOP be responsible or liable for any
            products or services of such third party providers. Additionally,
            Apple Inc., Google, Inc., and/or their applicable international
            subsidiaries and affiliates will be third-party beneficiaries to
            this contract if you access the Services using Applications
            developed for Apple iOS, and Android-powered mobile devices,
            respectively. These third-party beneficiaries are not parties to
            this contract and are not responsible for the provision or support
            of the Services in any manner. Your access to the Services using
            these devices is subject to terms set forth in the applicable
            third-party beneficiary’s terms of service.
          </div>
          <h3>Ownership</h3>
          <div>
            The Services and all rights therein are and shall remain HOP’s
            property or the property of HOP’s licensors. Neither these Terms nor
            your use of the Services convey or grant to you any rights: (i) in
            or related to the Services except for the limited license granted
            above; or (ii) to use or reference in any manner HOP’s company
            names, logos, product and service names, trademarks or services
            marks or those of HOP’s licensors.
          </div>
          <h2>3. Your Use of the Services</h2>
          <h3>User Accounts</h3>
          <div>
            In order to use most aspects of the Services, you must register for
            and maintain an active personal user Services account (“Account”).
            You must be at least 18 years of age, or the age of legal majority
            in your jurisdiction (if different than 18), to obtain an Account.
            Account registration requires you to submit to HOP certain personal
            information, such as your name, address, mobile phone number and
            age, as well as at least one valid payment method (either a credit
            card or accepted payment partner). You agree to maintain accurate,
            complete, and up-to-date information in your Account. Your failure
            to maintain accurate, complete, and up-to-date Account information,
            including having an invalid or expired payment method on file, may
            result in your inability to access and use the Services or HOP’s
            termination of these Terms with you. You are responsible for all
            activity that occurs under your Account, and you agree to maintain
            the security and secrecy of your Account username and password at
            all times. Unless otherwise permitted by HOP in writing, you may
            only possess one Account.
          </div>
          <h3>User Requirements and Conduct</h3>
          <div>
            The Service is not available for use by persons under the age of 18.
            You may not authorise third parties to use your Account, and you may
            not allow persons under the age of 18 to receive transportation or
            logistics services from Third Party Providers unless they are
            accompanied by you. You may not assign or otherwise transfer your
            Account to any other person or entity. You agree to comply with all
            applicable laws when using the Services, and you may only use the
            Services for lawful purposes (e.g., no transport of unlawful or
            hazardous materials). You will not, in your use of the Services,
            cause nuisance, annoyance, inconvenience, or property damage,
            whether to the Third-Party Provider or any other party. In certain
            instances, you may be asked to provide proof of identity to access
            or use the Services, and you agree that you may be denied access to
            or use of the Services if you
            <b>refuse to provide proof of identity.</b>
          </div>
          <h2>Text Messaging</h2>
          <div>
            By creating an Account, you agree that the Services may send you
            informational text (SMS) messages as part of the normal business
            operation of your use of the Services. You may opt-out of receiving
            text (SMS) messages from HOP at any time by sending an email to
            support@myhop.io indicating that you no longer wish to receive such
            messages, along with the phone number of the mobile device receiving
            the messages. You acknowledge that opting out of receiving text
            (SMS) messages may impact your use of the Services.
          </div>
          <h2>Promotional Codes</h2>
          <div>
            HOP may, in HOP’s sole discretion, create promotional codes that may
            be redeemed for Account credit, or other features or benefits
            related to the Services and/or a Third Party Provider's services,
            subject to any additional terms that HOP establishes on a per
            promotional code basis (“Promo Codes”). You agree that Promo Codes:
            (i) must be used for the intended audience and purpose, and in a
            lawful manner; (ii) may not be duplicated, sold or transferred in
            any manner, or made available to the general public (whether posted
            to a public form or otherwise), unless expressly permitted by HOP;
            (iii) may be disabled by HOP at any time for any reason without
            liability to HOP; (iv) may only be used pursuant to the specific
            terms that HOP establishes for such Promo Code; (v) are not valid
            for cash; and (vi) may expire prior to your use. HOP reserves the
            right to withhold or deduct credits or other features or benefits
            obtained through the use of Promo Codes by you or any other user in
            the event that HOP determines or believes that the use or redemption
            of the Promo Code was in error, fraudulent, illegal, or in violation
            of the applicable Promo Code terms or these Terms.
          </div>
          <h2>User Provided Content</h2>
          <div>
            HOP may, in HOP’s sole discretion, permit you from time to time to
            submit, upload, publish or otherwise make available to HOP through
            the Services textual, audio, and/or visual content and information,
            including commentary and feedback related to the Services,
            initiation of support requests, and submission of entries for
            competitions and promotions (“User Content”). Any User Content
            provided by you remains your property. However, by providing User
            Content to HOP, you grant HOP a worldwide, perpetual, irrevocable,
            transferrable, royalty-free license, with the right to sublicense,
            to use, copy, modify, create derivative works of, distribute,
            publicly display, publicly perform, and otherwise exploit in any
            manner such User Content in all formats and distribution channels
            now known or hereafter devised (including in connection with the
            Services and HOP’s business and on third-party sites and services),
            without further notice to or consent from you, and without the
            requirement of payment to you or any other person or entity.
          </div>
          <div>
            You represent and warrant that: (i) you either are the sole and
            exclusive owner of all User Content or you have all rights,
            licenses, consents and releases necessary to grant HOP the license
            to the User Content as set forth above; and (ii) neither the User
            Content nor your submission, uploading, publishing or otherwise
            making available of such User Content nor HOP’s use of the User
            Content as permitted herein will infringe, misappropriate or violate
            a third party's intellectual property or proprietary rights, or
            rights of publicity or privacy, or result in the violation of any
            applicable law or regulation.
          </div>
          <div>
            You agree to not provide User Content that is defamatory, libellous,
            hateful, violent, obscene, pornographic, unlawful, or otherwise
            offensive, as determined by HOP in its sole discretion, whether or
            not such material may be protected by law. HOP may, but shall not be
            obligated to, review, monitor, or remove User Content, at HOP’s sole
            discretion and at any time and for any reason, without notice to
            you.
          </div>
          <h2>Network Access and Devices</h2>
          <div>
            You are responsible for obtaining the data network access necessary
            to use the Services. Your mobile network’s data and messaging rates
            and fees may apply if you access or use the Services from a
            wireless-enabled device and you shall be responsible for such rates
            and fees. You are responsible for acquiring and updating compatible
            hardware or devices necessary to access and use the Services and
            Applications and any updates thereto. HOP does not guarantee that
            the Services, or any portion thereof, will function on any
            particular hardware or devices. In addition, the Services may be
            subject to malfunctions and delays inherent in the use of the
            Internet and electronic communications.
          </div>
          <div>
            4. Payment You understand that use of the Services may result in
            charges to you for the services or goods you receive from a
            Third-Party Provider (“Charges”). After you have received services
            or goods obtained through your use of the Service, HOP will
            facilitate your payment of the applicable Charges on behalf of the
            Third-Party Provider as such Third-Party Provider's limited payment
            collection agent. Payment of the Charges in such manner shall be
            considered the same as payment made directly by you to the
            Third-Party Provider.
          </div>
          <div>
            Charges will be inclusive of applicable taxes where required by law.
            Charges paid by you are final and non-refundable, unless otherwise
            determined by HOP. You retain the right to request lower Charges
            from a Third-Party Provider for services or goods received by you
            from such Third- Party Provider at the time you receive such
            services or goods. HOP will respond accordingly to any request from
            a Third-Party Provider to modify the Charges for a particular
            service or good.
          </div>
          <div>
            All Charges are due immediately and payment will be facilitated by
            HOP using the preferred payment method designated in your Account,
            after which HOP will send you a receipt via SMS. If your primary
            Account payment method is determined to be expired, invalid or
            otherwise not able to be charged, you agree that HOP may, as the
            Third-Party Provider’s limited payment collection agent, use a
            secondary payment method in your Account, if available.
          </div>
          <div>
            As between you and HOP, HOP reserves the right to establish, remove
            and/ or revise Charges for any or all services or goods obtained
            through the use of the Services at any time in HOP’s sole
            discretion. Further, you acknowledge and agree that Charges
            applicable in certain geographical areas may increase substantially
            during times of high demand. HOP will use reasonable efforts to
            inform you of Charges that may apply, provided that you will be
            responsible for Charges incurred under your Account regardless of
            your awareness of such Charges or the amounts thereof. HOP may from
            time to time provide certain users with promotional offers and
            discounts that may result in different amounts charged for the same
            or similar services or goods obtained through the use of the
            Services, and you agree that such promotional offers and discounts,
            unless also made available to you, shall have no bearing on your use
            of the Services or the Charges applied to you. You may elect to
            cancel your request for services or goods from a Third-Party
            Provider at any time prior to such Third Party Provider's arrival,
            in which case you may be charged a cancellation fee.
          </div>
          <div>
            This payment structure is intended to fully compensate the
            Third-Party Provider for the services or goods provided. Except with
            respect to taxicab transportation services requested through the
            Application, HOP does not designate any portion of your payment as a
            tip or gratuity to the Third-Party Provider. Any representation by
            HOP (on HOP’s website, in the Application, or in HOP’s marketing
            materials) to the effect that tipping is “voluntary,” “not
            required,” and/or “included” in the payments you make for services
            or goods provided is not intended to suggest that HOP provides any
            additional amounts, beyond those described above, to the Third Party
            Provider. You understand and agree that, while you are free to
            provide additional payment as a gratuity to any Third-Party Provider
            who provides you with services or goods obtained through the
            Service, you are under no obligation to do so. Gratuities are
            voluntary. After you have received services or goods obtained
            through the Service, you will have the opportunity to rate your
            experience and leave additional feedback about your Third-Party
            Provider.
          </div>
          <h2>Repair or Cleaning Fees</h2>
          <div>
            You shall be responsible for the cost of repair for damage to, or
            necessary cleaning of, Third Party Provider vehicles and property
            resulting from use of the Services under your Account in excess of
            normal “wear and tear” damages and necessary cleaning (“Repair or
            Cleaning”). In the event that a Third Party Provider reports the
            need for Repair or Cleaning, and such Repair or Cleaning request is
            verified by HOP in HOP’s reasonable discretion, HOP reserves the
            right to facilitate payment for the reasonable cost of such Repair
            or Cleaning on behalf of the Third Party Provider using your payment
            method designated in your Account. Such amounts will be transferred
            by HOP to the applicable Third-Party Provider and are non-
            refundable.
          </div>
          <div>5. Disclaimers; Limitation of Liability; Indemnity.</div>
          <div>DISCLAIMER</div>
          <div>
            THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” HOP DISCLAIMS
            ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY,
            NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT. IN ADDITION, HOP MAKES NO REPRESENTATION,
            WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS,
            QUALITY, SUITABILITY OR AVAILABILITY OF THE SERVICES OR ANY SERVICES
            OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE
            SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE. HOP DOES NOT GUARANTEE
            THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD PARTY
            PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF
            THE SERVICES, AND ANY SERVICE OR GOOD REQUESTED IN CONNECTION
            THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED
            UNDER APPLICABLE LAW.
          </div>
          <div>LIMITATION OF LIABILITY</div>
          <div>
            HOP SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL,
            EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES, INCLUDING LOST
            PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO,
            IN CONNECTION WITH, OR OTHERWISE RESULTING FROM ANY USE OF THE
            SERVICES, EVEN IF HOP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. HOP SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR
            LOSSES ARISING OUT OF: (I) YOUR USE OF OR RELIANCE ON THE SERVICES
            OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (Il) ANY
            TRANSACTION OR RELATIONSHIP BETWEEN YOU AND ANY THIRD PARTY
            PROVIDER, EVEN IF HOP HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES. HOP SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE
            RESULTING FROM CAUSES BEYOND HOP’S REASONABLE CONTROL. YOU
            ACKNOWLEDGE THAT THIRD PARTY TRANSPORTATION PROVIDERS PROVIDING
            TRANSPORTATION SERVICES REQUESTED THROUGH SOME REQUEST BRANDS MAY
            OFFER RIDE-SHARING OR PEER-TO-PEER TRANSPORTATION SERVICES AND MAY
            NOT BE PROFESSIONALLY LICENSED OR PERMITTED. INNO EVENT SHALL HOP’S
            TOTAL LIABILITY TO YOU IN CONNECTION WITH THE SERVICES FOR ALL
            DAMAGES, LOSSES AND CAUSES OF ACTION EXCEED FIVE HUNDRED AUSTRALIAN
            DOLLARS ($500).
          </div>
          <div>
            HOP’S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE
            TRANSPORTATION, GOODS OR LOGISTICS SERVICES WITH THIRD PARTY
            PROVIDERS, BUT YOU AGREE THAT HOP HAS NO RESPONSIBILITY OR LIABILITY
            TO YOU RELATED TO ANY TRANSPORTATION, GOODS OR LOGISTICS SERVICES
            PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET
            FORTH IN THESE TERMS.
          </div>
          <div>
            THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT TO
            LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A CONSUMER THAT CANNOT BE
            EXCLUDED UNDER APPLICABLE LAW. Indemnity
          </div>
          <div>
            You agree to indemnify and hold HOP and its officers, directors,
            employees and agents harmless from any and all claims, demands,
            losses, liabilities, and expenses (including attorneys’ fees)
            arising out of or in connection with: (i) your use of the Services
            or services or goods obtained through your use of the Services; (ii)
            your breach or violation of any of these Terms; (iii) HOP’s use of
            your User Content; or (iv) your violation of the rights of any third
            party, including Third Party Providers.
          </div>
          <h1>6. Governing Law; Arbitration.</h1>
          <div>
            Except as otherwise set forth in these Terms, these Terms shall be
            exclusively governed by and construed in accordance with the laws of
            Australia, excluding its rules on conflicts of laws. The Vienna
            Convention on the International Sale of Goods of 1980 (CISG) shall
            not apply. Any dispute, conflict, claim or controversy arising out
            of or broadly in connection with or relating to the Services or
            these Terms, including those relating to its validity, its
            construction or its enforceability (any “Dispute”) shall be first
            mandatorily submitted to mediation proceedings under the
            International Chamber of Commerce Mediation Rules (“ICC Mediation
            Rules”). If such Dispute has not been settled within sixty (60) days
            after a request for mediation has been submitted under such ICC
            Mediation Rules, such Dispute can be referred to and shall be
            exclusively and finally resolved by arbitration under the Rules of
            Arbitration of the International Chamber of Commerce (“ICC
            Arbitration Rules”). The ICC Rules' Emergency Arbitrator provisions
            are excluded. The Dispute shall be resolved by one (1) arbitrator to
            be appointed in accordance with the ICC Rules. The place of both
            mediation and arbitration shall be NSW, Australia, without prejudice
            to any rights you may have under Competition and Consumer Act 2010
            and Australian Consumer Law. The language of the mediation and/or
            arbitration shall be English, unless you do not speak English, in
            which case the mediation and/or arbitration shall be conducted in
            both English and your native language. The existence and content of
            the mediation and arbitration proceedings, including documents and
            briefs submitted by the parties, correspondence from and to the
            International Chamber of Commerce, correspondence from the mediator,
            and correspondence, orders and awards issued by the sole arbitrator,
            shall remain strictly confidential and shall not be disclosed to any
            third party without the express written consent from the other party
            unless: (i) the disclosure to the third party is reasonably required
            in the context of conducting the mediation or arbitration
            proceedings; and (ii) the third party agrees unconditionally in
            writing to be bound by the confidentiality obligation stipulated
            herein.
          </div>
          <h1>7. Other Provisions</h1>
          <h2>Claims of Copyright Infrigement</h2>
          <div>
            Claims of copyright infringement should be sent to HOP’s designated
            office address noted below.
          </div>
          <h2>Notice</h2>
          <div>
            HOP may give notice by means of a general notice on the Services,
            electronic mail to your email address in your Account, or by written
            communication sent to your address as set forth in your Account. You
            may give notice to HOP by written communication to HOP’s address at
            Suite 205, 2-8 Brookhollow Avenue, Baulkham Hills NSW 2153.
          </div>
          <h2>General</h2>
          <div>
            You may not assign or transfer these Terms in whole or in part
            without HOP’s prior written approval. You give your approval to HOP
            for it to assign or transfer these Terms in whole or in part,
            including to: (i) a subsidiary or affiliate; (ii) an acquirer of
            HOP’s equity, business or assets; or (iii) a successor by merger. No
            joint venture, partnership, employment or agency relationship exists
            between you, HOP or any Third Party Provider as a result of the
            contract between you and HOP or use of the Services.
          </div>
          <div>
            If any provision of these Terms is held to be illegal, invalid or
            unenforceable, in whole or in part, under any law, such provision or
            part thereof shall to that extent be deemed not to form part of
            these Terms but the legality, validity and enforceability of the
            other provisions in these Terms shall not be affected. In that
            event, the parties shall replace the illegal, invalid or
            unenforceable provision or part thereof with a provision or part
            thereof that is legal, valid and enforceable and that has, to the
            greatest extent possible, a similar effect as the illegal, invalid
            or unenforceable provision or part thereof, given the contents and
            purpose of these Terms. These Terms constitute the entire agreement
            and understanding of the parties with respect to its subject matter
            and replaces and supersedes all prior or contemporaneous agreements
            or undertakings regarding such subject matter. In these Terms, the
            words “including” and “include” mean “including, but not limited
            to.”
          </div>
        </article>
      </BodyCopy>
      <Footer />
    </Container>
  );
};
export default Home;
